/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-gray-800 text-sm font-bold leading-relaxed inline-block py-0 whitespace-no-wrap uppercase"
              style={{width: '250px'}}
            >
            <img
              className=""
              src={require("assets/img/logotipo naranja.png")}
              alt="..."
            />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
            </Link>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            {/* <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-gray-600 text-black px-3 py-4 lg:py-2 flex items-center text-3xl font-bold font-regular text-light"
                  href="#"
                >
                  {" "}
                  Red de Pagos
                </a>
              </li>
            </ul> */}
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <Link
                className=" md:bg-teal-900 md:hover:bg-green-700  hover:bg-red-700 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                  to="/"
                  >
                  Inicio
              </Link>
            </li>
            <li className="flex items-center">
              <Link
                className=" md:bg-teal-900 md:hover:bg-green-700  hover:bg-red-700 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                  to="/nosotros"
                  >
                  Nosotros
              </Link>
            </li>
            {/* <li className="flex items-center">
              <a
                className="hover:text-gray-100 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                href="#">
                Servicios
              </a>
            </li> */}
            {/* <li className="flex items-center">
              <a
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                href="#">
                Noticias
              </a>
            </li> */}
            {/* <li className="flex items-center">
              <a
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                href="http://admin.reddepagos.co/login">
                Distribuidores
              </a>
            </li> */}
            <li className="flex items-center">
              <Link
                className=" md:bg-teal-900 md:hover:bg-green-700  hover:bg-red-700 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-regular"
                  to="/contacto"
                  >
                  Contactanos
              </Link>
            </li>
              <li className="flex items-center">
                <a
                  className="bg-teal-900 text-white active:bg-green-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                  href="https://reddepagos.ventual.co/login"
                >
                  <i className="fa fa-user-circle mr-1"></i> Ingresa
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
