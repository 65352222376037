/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";


export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen bg-cover max-h-860-px bg-no-repeat h-screen"
      style={{
        backgroundImage:
          "url(" + require("assets/img/header.jpg") + ")",
        backgroundSize:
          "cover",
      }}
      >
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="sm:pt-0">
              <h2 className="font-semibold text-4xl text-white">
                <strong>RED DE PAGOS</strong> - Realiza tus transacciones online
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-white">
                Plataforma tecnológica para impulsar el comercio electrónico en micro-negocios.
              </p>
              <div className="mt-12">
                <a
                  href="#"
                  target="_blank"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-teal-900 active:bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Tranferencias
                </a>
                <a
                  href="#"
                  className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-black active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  target="_blank"
                >
                  Recargas
                </a>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-20 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png")}
          alt="..."
        />
      </section>

      {/* <section className="pb-16 relative pt-32 bg-opacity-75 mt-24">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-10 px-4 relative z-10">
            <div className="w-full text-center lg:w-8/12">
              <p className="text-4xl text-center">
              </p>
              <h3 className="font-bold text-4xl uppercase">
                Simulador de transferencia
              </h3>
              <form>
                <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  Ingresa los datos
                </h6>
                <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Monto
                    </label>
                    <input
                      type="text"
                      className="bg-gray-200 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="mt-4 w-full lg:w-8/12 px-4">
                  <span className="block uppercase text-gray-700 text-xs font-bold mb-2">Tipo de transferencia</span>
                  <div className="mt-2">
                    <label className="inline-flex items-center w-full lg:w-6/12 px-4">
                      <input type="radio" className="form-radio active" name="accountType" value="personal"/>
                      <span className="ml-2"><b>(Pesos)</b> - Bolivares </span>
                    </label>
                    <label className="inline-flex items-center w-full lg:w-6/12 px-46">
                      <input type="radio" className="form-radio" name="accountType" value="busines"/>
                      <span className="ml-2"><b>(Bolivares)</b> - Pesos</span>
                    </label>
                  </div>
                </div>
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Tasa (0.0018)
                      </label>
                      <input
                        type="text"
                        className="bg-gray-200 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Reultado"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="sm:block flex flex-col mt-10">
                <a
                  href="#"
                  target="_blank"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-teal-900 active:bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                <i className="fas fa-check text-lg mr-1"></i>
                <span>Confirmar</span>
                </a>
                <a
                  href="https://github.com/creativetimofficial/notus-react?ref=nr-index"
                  target="_blank"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-black active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fas fa-phone text-lg mr-1"></i>
                  <span>Contactar</span>
                </a>
              </div>
              <div className="text-center mt-5"></div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="mt-48 md:mt-40 pb-40 relative bg-gray-200">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-teal-900">
                <img
                  alt="..."
                  src="https://image.freepik.com/foto-gratis/mano-que-sostiene-control-remoto-tv-smart-tv_44251-244.jpg"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4 bg-teal-900">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-teal-900 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    ¿Que puedes hacer en Ventual Red de Pagos?
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Somos una red integral y eficiente diseñada para simplificar, agilizar las venta y distribuir productos y servicios que puedan ser transados o comercializados al por mayor o menudeo.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-orange-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                        <i className="fas fa-exchange-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Amplio portafolio de productos
                      </h6>
                      <p className="mb-4 text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt aliqua.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-orange-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                        <i className="fas fa-qrcode"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Contenidos  digitales
                      </h6>
                      <p className="mb-4 text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt aliqua.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-orange-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                        <i className="fas fa-mobile-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Recarga a operadores móviles</h6>
                      <p className="mb-4 text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt aliqua.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-orange-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-black">
                        <i className="fas fa-futbol"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Apuestas  deportivas
                      </h6>
                      <p className="mb-4 text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt aliqua.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          className="top-0 b-auto right-0 py-20  w-12/12"
          src={require("assets/img/div.jpg")}
          alt="..."
        />

        <div className="container mx-auto overflow-hidden pb-20">
        <h3 className="font-bold text-2xl uppercase mb-6 text-center">
          Por qué estás tomando la decisión correcta
        </h3>
        <div className="flex flex-wrap " 
        style={{
          backgroundImage:
            "url(" + require("assets/img/process-bg.png") + ")",
        }}
        >
          <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                  <i className="fas fa-award"></i>
                </div>
                <h6 className="text-xl font-semibold">Precios claros desde el principio</h6>
                <p className="mt-2 mb-4 text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-900">
                  <i className="fas fa-retweet"></i>
                </div>
                <h6 className="text-xl font-semibold">Tarifas muy bajas</h6>
                <p className="mt-2 mb-4 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-500">
                      <i className="fas fa-clock"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Siempre a tiempo</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                  </div>
                </div>
              </div>
            </div>

          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-orange-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-black">
                <i className="fas fa-user text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Crea tu perfil hoy mismo
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                component. All components fit perfectly with each other and can
                have different colours.
              </p>
              <div className="block pb-6">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Recargas
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Paquetes
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Pines
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Entretinimiento
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Videojuegos
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Transferencias
                </span>
              </div>
              <a
                href="#"
                target="_blank"
                className="font-bold text-gray-800 hover:text-gray-600 ease-linear transition-all duration-150"
              >
                Crear ahora{" "}
                <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={require("assets/img/component-btn.png")}
                  className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/component-profile-card.png")}
                  className="w-full align-middle rounded-lg absolute -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/component-info-card.png")}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <img
                  alt="..."
                  src={require("assets/img/component-info-2.png")}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                />
                {/* <img
                  alt="..."
                  src={require("assets/img/component-menu.png")}
                  className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"
                /> */}
                <img
                  alt="..."
                  src={require("assets/img/component-btn-pink.png")}
                  className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
