import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-teal-900 pt-8 pb-6"
      style={{
        // backgroundImage:
        //   "url(" + require("assets/img/footer.jpg") + ")",
        //   backgroundAttachment:
        //        "fixed",
        backgroundColor: '#024854'
      }}>
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
            style={{
              // backgroundImage:
              //   "linear-gradient(45deg, #7cdf50, #15b263)",
              // borderBottom:
              //   "solid 1px #fff",
              backgroundColor: '#024854'

            }}
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 -2560 -100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold text-white">Siguenos en las redes sociales</h4>
              <h5 className="text-lg mt-0 mb-2 text-white">
                No te pierdas las mejores ofertas y descuentos.
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <a href="https://www.facebook.com/Red-de-pagos-104867711647692/" target="_blank">
                  <button
                    className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button">
                    <i className="fab fa-facebook-square"></i>
                  </button>
                </a>
                <a href="https://www.instagram.com/ventualco?igsh=MTJ3ZG03bzY5MWV3MQ==" target="_blank">
                  <button
                    className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button">
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-white text-sm font-semibold mb-2">
                    Empresa
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Nosotros
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Blog
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Recargas
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Legal
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-white text-sm font-semibold mb-2">
                    Mas informacion
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Legales
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Terminos & Condiciones
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Seguridad
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="#"
                      >
                        Contactanos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} Ventual Red de pagos
                {/* <a
                  href="https://developapp.co/"
                  className="text-white hover:text-gray-900"
                >
                  Developapp
                </a> */}
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
